import { Button, Col, Flex, Row } from '@appliedsystems/applied-design-system';
import { LocaleCode } from '@appliedsystems/payments-core';
import React from 'react';
import { useAccountManagement } from '../../hooks/useAccountManagement';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { useAccountManagementStore } from '../../store/AccountManagement';
import { useAgencyDetailsStore } from '../../store/AgencyDetail';
import { Locale } from '../../store/Locale';
import { getConfig } from '../../util/config';
import { AccountManagementModals } from '../AccountManagementModals';
import { useHppSessionStore } from '../HostedPaymentPageContainer/useHppSession';
import { Logo } from '../Logo';
import { ManageAccount } from '../ManageAccount';
import styles from '../PaymentHeader/PaymentHeader.module.scss';
import { Title } from '../Title';

export const Header = ({ isConfirmationPage }: { isConfirmationPage: boolean }) => {
  const { t } = usePaymentsTranslation();
  const { data: agencyDetail, hppConfig } = useAgencyDetailsStore();
  const { locale, setLocale } = Locale.useContainer();
  const { createAccountModal, manageAccountWindow, loginModal, logout, customerUser } = useAccountManagementStore();

  const { hppSession } = useHppSessionStore();
  const paymentFlowSessionId = hppSession?.paymentFlowSessionId;
  const hppToken = agencyDetail?.token;
  const paymentSessionToken = paymentFlowSessionId ?? hppToken;

  const { isAccountManagementEnabled: isAccountManagementFlagEnabled } = useAccountManagement(
    agencyDetail?.appliedProductId,
    paymentSessionToken,
  );

  const isAccountManagementEnabled = isAccountManagementFlagEnabled && agencyDetail?.manageMyAccountEnabled;

  const toggleCanadianLocale = () => {
    const newLocale = locale === LocaleCode.en_CA ? LocaleCode.fr_CA : LocaleCode.en_CA;
    setLocale(newLocale);
  };
  const isCanadianLocale = [LocaleCode.en_CA, LocaleCode.fr_CA].includes(locale);
  const localeToggleButtonLabel = locale === LocaleCode.en_CA ? 'Voir en français' : 'View in English';

  return (
    <>
      <Row>
        <Col xs={12}>
          <div className={styles.wrapper}>
            {isCanadianLocale && (
              <Button type="link" className="mr-100" onClick={toggleCanadianLocale}>
                {localeToggleButtonLabel}
              </Button>
            )}
            {isAccountManagementEnabled && !isConfirmationPage && (
              <>
                {customerUser ? (
                  <Flex>
                    <div>
                      <Button type="link" className="mr-100" onClick={manageAccountWindow.open}>
                        {customerUser.email}
                      </Button>

                      <Button type="link" onClick={logout}>
                        Log out
                      </Button>
                    </div>
                  </Flex>
                ) : (
                  <Flex>
                    <Button type="link" onClick={loginModal.open}>
                      {t('LOG_IN')}
                    </Button>
                    <Button type="link" onClick={createAccountModal.open}>
                      {t('CREATE_ACCOUNT')}
                    </Button>
                  </Flex>
                )}

                {paymentSessionToken && <AccountManagementModals paymentSessionToken={paymentSessionToken} />}

                {paymentSessionToken && (
                  <ManageAccount
                    brand={{
                      name: agencyDetail?.name ?? '',
                      logoUrl:
                        hppConfig.showLogo && agencyDetail?.imageId
                          ? `${getConfig('REACT_APP_PAYMENTS_API_BASE_URL')}/image/${agencyDetail?.imageId}`
                          : undefined,
                    }}
                    locale={locale}
                    paymentSessionToken={paymentSessionToken}
                  />
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
      {hppConfig.showLogo && !!agencyDetail?.imageId && (
        <Row>
          <Col xs={12}>
            <Logo imageId={agencyDetail.imageId} alt={agencyDetail.name || ''} />
          </Col>
        </Row>
      )}
      {hppConfig.showTitle && !isConfirmationPage && (
        <Row>
          <Col xs={12}>
            <Title title={t('MAKE_A_PAYMENT')} description={t('ENTER_PAYMENT_INFORMATION')} />
          </Col>
        </Row>
      )}
    </>
  );
};
