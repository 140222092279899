import { Button, Container, Flex, H1, Tab, TabGroup } from '@appliedsystems/applied-design-system';
import { AppliedProductId, LocaleCode } from '@appliedsystems/payments-core';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { useAccountManagementStore } from '../../store/AccountManagement';
import { useAgencyDetailsStore } from '../../store/AgencyDetail';
import { getConfig } from '../../util/config';
import { Autopay } from '../Autopay/Autopay';
import { Logo } from '../Logo';
import styles from './ManageAccount.module.scss';
import { PaymentHistory } from './PaymentHistory';
import { PaymentMethods } from './PaymentMethods';

const AUTOPAY_TENANTS = getConfig('REACT_APP_AUTOPAY_TENANTS').trim();

type Props = {
  brand?: {
    logoUrl?: string;
    name?: string;
  };
  // TODO: Add useLocale store hook to get locale
  locale?: LocaleCode;
  paymentSessionToken: string;
};

export const ManageAccount = ({ brand, locale, paymentSessionToken }: Props) => {
  const { manageAccountWindow, customerUser } = useAccountManagementStore();
  const { t } = usePaymentsTranslation();
  const { data: agencyDetail } = useAgencyDetailsStore();

  useEffect(() => {
    if (!manageAccountWindow.isOpen) {
      return;
    }

    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        manageAccountWindow.close();
      }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [manageAccountWindow]);

  if (!customerUser && manageAccountWindow.isOpen) {
    console.warn('Customer user not found');
    manageAccountWindow.close();
    return null;
  }

  const autopayFlagEnabled =
    AUTOPAY_TENANTS === '*' || AUTOPAY_TENANTS.split(' ').includes(customerUser?.tenantId as string);
  const isEpicConnected =
    customerUser?.agency.appliedProductId === AppliedProductId.Epic ||
    customerUser?.agency.appliedProductId === AppliedProductId.Csr24;
  const autopayEnabled =
    autopayFlagEnabled &&
    isEpicConnected &&
    (customerUser.merchantAccounts.some((ma) => ma.autopayEnabled) ||
      agencyDetail?.autopayEnabled ||
      customerUser.autopayEnrollments.length);

  return (
    <Container className={classNames(styles.container, manageAccountWindow.isOpen && styles.open)}>
      <Logo src={brand?.logoUrl} alt={brand?.name} />
      <Flex className={styles.header}>
        <H1>{t('ACCOUNT_SETTINGS_MANAGE_ACCOUNT')}</H1>
        <Button type="link" onClick={manageAccountWindow.close}>
          {t('ACCOUNT_SETTINGS_BACK')}
        </Button>
      </Flex>

      {manageAccountWindow.isOpen && (
        <TabGroup headersClassName="mb-100">
          <Tab title={t('ACCOUNT_SETTINGS_TAB_TITLE_PAYMENT_METHODS')} tabKey="payment-methods">
            <PaymentMethods paymentSessionToken={paymentSessionToken} />
          </Tab>
          <Tab title={t('ACCOUNT_SETTINGS_TAB_TITLE_PAYMENT_HISTORY')} tabKey="payment-history">
            <PaymentHistory locale={locale} />
          </Tab>
          {autopayEnabled && (
            <Tab title={t('AUTOPAY')} tabKey="autopay">
              <Autopay />
            </Tab>
          )}
        </TabGroup>
      )}
    </Container>
  );
};
