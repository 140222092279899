import {
  Button,
  ButtonContainer,
  Col,
  DataGrid,
  DataGridColumnType,
  OneOrMore,
  RowAction,
  SectionV2,
} from '@appliedsystems/applied-design-system';
import { AutopayEnrollment as Enrollment, AutopayType, toIntlFormat } from '@appliedsystems/payments-core';
import { useQuery } from '@tanstack/react-query';
import React, { FC, useMemo, useState } from 'react';
import { ApiClient } from '../../api/ApiClient';
import { currencyMap } from '../../constants/constants';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { useAccountManagementStore } from '../../store/AccountManagement';
import { Locale } from '../../store/Locale';
import { toLocaleDateTimeString } from '../../util/date';
import { FlexRow } from '../../util/react';
import { useHttpWrapper } from '../../util/rest';
import { cardCompanies } from './AutopayEnrollment';
import { CancelAutopayModal } from './CancelAutopayModal';

export const AutopayList: FC<{
  goToEdit: (enrollment?: Enrollment) => void;
}> = ({ goToEdit }) => {
  const { t } = usePaymentsTranslation();
  const { locale } = Locale.useContainer();
  const currencyCode = currencyMap[locale];
  const { customerUser } = useAccountManagementStore();
  const { wrapQuery } = useHttpWrapper();

  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [enrollmentIdToCancel, setEnrollmentIdToCancel] = useState<string>();

  const storedMethods = useQuery({
    queryKey: ['autopayList/paymentMethods'],
    queryFn: wrapQuery(() => ApiClient.getInstance().getStoredPaymentMethods(), {
      transformData: (data) => [
        ...data.cards.map((c) => ({
          recurringDetailReference: c.recurringDetailReference,
          displayName:
            (c.paymentMethodNickname ? c.paymentMethodNickname + ' (' : '') +
            (cardCompanies[c.variant!] ?? t('CARD')) +
            (c.card?.number
              ? ' ' + t('ENDING_WITH', undefined, { lastFourDigits: c.card.number.slice(-4) } as any)
              : '') +
            (c.paymentMethodNickname ? ')' : ''),
        })),
        ...data.ach.map((a) => ({
          recurringDetailReference: a.recurringDetailReference,
          displayName:
            (a.paymentMethodNickname ? a.paymentMethodNickname + ' (' : '') +
            (a.bank?.ownerName || '') +
            (a.bank?.ownerName && a.bank.bankAccountNumber ? ' - ' : '') +
            (a.bank?.bankAccountNumber ? `*****${a.bank.bankAccountNumber.slice(-4)}` : '') +
            (a.paymentMethodNickname ? ')' : ''),
        })),
      ],
    }),
    retry: false,
    refetchOnWindowFocus: false,
  });

  const isEnrolled = customerUser && customerUser.autopayEnrollments.length > 0;

  const columns = useMemo<DataGridColumnType<Enrollment>[]>(() => {
    return [
      {
        name: 'type',
        title: t('ENROLLMENT_TYPE'),
        cellRenderer: (row) => t(row.type),
      },
      {
        name: 'enrollmentInfo',
        title: t('ENROLLED_FOR'),
        cellRenderer: (row) => {
          switch (row.type) {
            case AutopayType.INVOICES:
              return row.invoices.map((i) => i.invoiceNumber).join(' ');
            case AutopayType.POLICIES:
              return row.policies.map((p) => p.policyNumber).join(' ');
            case AutopayType.ACCOUNT:
              return row.epicClient.epicLookupCode;
          }
        },
        isSortable: false,
      },
      {
        name: 'paymentMethod',
        title: t('PAYMENT_METHOD'),
        cellRenderer: (row) =>
          storedMethods.data?.find(
            (m) => m.recurringDetailReference === row.storedPaymentMethod.recurringDetailReference,
          )?.displayName || '',
        sortValue: (row) =>
          storedMethods.data?.find(
            (m) => m.recurringDetailReference === row.storedPaymentMethod.recurringDetailReference,
          )?.displayName || '',
      },
      {
        name: 'lastPaymentDate',
        title: t('LAST_PAYMENT_DATE'),
        cellRenderer: (row) => (row.lastPayment ? toLocaleDateTimeString(new Date(row.lastPayment.createdAt)) : ''),
        sortValue: (row) => toLocaleDateTimeString(row.lastPayment?.createdAt) || '',
      },
      {
        name: 'lastPaymentAmount',
        title: t('LAST_PAYMENT_AMOUNT'),
        cellRenderer: (row) =>
          row.lastPayment ? toIntlFormat({ amount: Number(row.lastPayment.amount), currencyCode }, locale) : '',
        sortValue: (row) => row.lastPayment?.amount || 0,
      },
      {
        name: 'status',
        title: t('STATUS'),
        cellRenderer: (row) => (row.deactivatedBy ? t('INACTIVE') : t('ACTIVE')),
        sortValue: (row) => row.deactivatedBy || '',
      },
    ];
  }, [locale, t, currencyCode, storedMethods.data]);

  const actions = useMemo(
    () =>
      [
        {
          type: 'non-nested',
          label: t('EDIT'),
          onClick: ([enrollmentIdToCancel]) => {
            const enrollment = customerUser?.autopayEnrollments.find(
              (enrollment) => enrollment.id === enrollmentIdToCancel,
            );
            goToEdit(enrollment);
          },
        },
        {
          type: 'non-nested',
          label: t('CANCEL'),
          onClick: ([enrollmentIdToCancel]) => {
            setEnrollmentIdToCancel(enrollmentIdToCancel as string);
            setCancelModalOpen(true);
          },
        },
      ] satisfies OneOrMore<RowAction>,
    [goToEdit, customerUser, t, setEnrollmentIdToCancel, setCancelModalOpen],
  );

  if (!isEnrolled)
    return (
      <SectionV2 title={t('ENROLL_IN_AUTOPAY')} description={t('ENROLL_IN_AUTOPAY_INSTRUCTIONS')}>
        <FlexRow gap="1.5rem">
          <Button onClick={() => goToEdit()}>{t('ENROLL_IN_AUTOPAY')}</Button>
        </FlexRow>
      </SectionV2>
    );

  return (
    <SectionV2 title={t('YOU_ARE_ENROLLED_IN_AUTO_PAY')}>
      <ButtonContainer>
        <Button type="primary" className="mb-100" icon="AddIcon" onClick={() => goToEdit()}>
          {t('ADD')}
        </Button>
      </ButtonContainer>
      <FlexRow>
        <Col xs={12}>
          <DataGrid<Enrollment>
            testId="autoPayDataGridTest"
            columns={columns}
            rowIdField="id"
            rows={customerUser.autopayEnrollments}
            rowActionsConfig={{
              type: 'multi-action',
              actions,
              buttonConfig: {
                type: 'meatball',
                enabled: true,
              },
            }}
          />
        </Col>
      </FlexRow>

      <CancelAutopayModal
        modalOpen={cancelModalOpen}
        onClose={() => setCancelModalOpen(false)}
        enrollmentIdToCancel={enrollmentIdToCancel}
      />
    </SectionV2>
  );
};
