import {
  ADSChangeEvent,
  Button,
  DataGrid,
  DataGridColumnType,
  Form,
  PhoneField,
  phoneNumber,
  Radio,
  RadioGroup,
  SectionTypes,
  SectionV2,
  TextField,
} from '@appliedsystems/applied-design-system';
import {
  AutopayEnrollment as Enrollment,
  AutopayType,
  Dict,
  EpicPolicy,
  PaymentMethod,
  StoredPaymentMethod,
  toIntlFormat,
} from '@appliedsystems/payments-core';
import { datadogLogs } from '@datadog/browser-logs';
import { useMutation, useQuery } from '@tanstack/react-query';
import { format } from 'date-fns-tz';
import React, { FC, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { ApiClient } from '../../api/ApiClient';
import { currencyMap } from '../../constants/constants';
import { TranslationKey, usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { useAccountManagementStore } from '../../store/AccountManagement';
import { useAgencyDetailsStore } from '../../store/AgencyDetail';
import { Locale } from '../../store/Locale';
import { useValidEpicAccountStore } from '../../store/ValidEpicAccount';
import { getConfig } from '../../util/config';
import { ComboboxObject, FlexCol, FlexRow, InfoTooltip, RowCol } from '../../util/react';
import { useHttpWrapper } from '../../util/rest';
import { AcceptCCFeeCheckbox } from '../AcceptCCFeeCheckbox/AcceptCCFeeCheckbox';
import { ErrorAlert } from '../ErrorAlert/ErrorAlert';
import { InvoiceDataGrid, InvoiceGroup } from '../HostedPaymentPage/workflows/InvoiceDataGrid';
import { Loading } from '../Loading';
import { AddPaymentMethodModal } from '../ManageAccount/PaymentMethods/AddPaymentMethodModal';
import paymentMethodContainerClasses from '../PaymentMethodContainer/PaymentMethodContainer.module.scss';
import classes from './Autopay.module.scss';
import { CancelAutopayModal } from './CancelAutopayModal';

export const cardCompanies: Dict<string> = {
  visa: 'Visa',
  mc: 'Mastercard',
  amex: 'Amex',
  discover: 'Discover',
};

const reformatYMDtoMDY = (date: string): string => {
  try {
    const [_, y, m, d] = /(\d{4})-(\d{2})-(\d{2})/.exec(date)!;
    return `${m}/${d}/${y}`;
  } catch {
    return date;
  }
};

const warningCutoff = new Date(
  new Date().getTime() + Number(getConfig('REACT_APP_AUTOPAY_WARNING_DAYS')) * 24 * 60 * 60 * 1000,
);

export const AutopayEnrollment: FC<{
  enrollment?: Enrollment;
  goBackToList: () => void;
}> = ({ enrollment, goBackToList }) => {
  const { t } = usePaymentsTranslation();
  const { locale } = Locale.useContainer();
  const currencyCode = currencyMap[locale];
  const { wrapMutation, wrapQuery } = useHttpWrapper();
  const { data: agencyDetail, isLoading: agencyDetailIsLoading } = useAgencyDetailsStore();
  const { customerUser: _customerUser, refreshCustomerUser } = useAccountManagementStore();
  const { lastValidatedEpicClient } = useValidEpicAccountStore();
  // don't allow customerUser to change once component loads (useAccountManagementStore likes to refresh it constantly)
  const [customerUser, setCustomerUser] = useState(_customerUser);
  useEffect(() => {
    if (_customerUser && !customerUser) setCustomerUser(_customerUser);
  }, [_customerUser, customerUser]);

  //
  // account validation
  const [errorMessage, setErrorMessage] = useState<[translationKey: TranslationKey, replacements?: Dict<string>]>();
  const [client, setClient] = useState<
    | {
        clientId: string;
        lookupCode: string;
        accountName: string;
        postalCode: string;
        phoneNumber: string | null;
      }
    | undefined
  >(
    enrollment?.epicClient
      ? {
          clientId: enrollment.epicClient.epicClientId,
          lookupCode: enrollment.epicClient.epicLookupCode,
          accountName: enrollment.epicClient.epicAccountName,
          postalCode: enrollment.epicClient.postalCode,
          phoneNumber: enrollment.epicClient.phoneNumber,
        }
      : undefined,
  );
  const [autopayType, setAutopayType] = useState(enrollment?.type ?? AutopayType.ACCOUNT);

  const accountSchema = useMemo(
    () =>
      yup.object({
        accountNumber: yup
          .string()
          .required()
          .label(t('ACCOUNT_NUMBER'))
          .default(enrollment?.epicClient.epicLookupCode ?? getConfig('REACT_APP_DEFAULT_ACCOUNT_CODE', false)),
        phoneNumber: phoneNumber()
          .required()
          .label(t('PHONE_NUMBER'))
          .default(enrollment?.epicClient.phoneNumber ?? getConfig('REACT_APP_DEFAULT_ACCOUNT_PHONE', false) ?? ''),
        postalCode: yup
          .string()
          .required()
          .label(t('POSTAL_CODE'))
          .default(enrollment?.epicClient.postalCode ?? getConfig('REACT_APP_DEFAULT_ACCOUNT_POSTAL', false)),
      }),
    [t, enrollment],
  );

  const validateAccount = useMutation(
    wrapMutation(
      async (values: yup.InferType<typeof accountSchema>) => {
        setErrorMessage(undefined);
        return ApiClient.getInstance().validateEpicAccountCode(agencyDetail!.token, {
          accountCode: values.accountNumber,
          phoneNumber: values.phoneNumber.phoneNumber ?? undefined,
          postalCode: values.postalCode,
        });
      },
      {
        transformData: (result) => {
          switch (result.status) {
            case 'valid':
              setClient(result);
              break;
            case 'rate-limit-exceeded':
              setErrorMessage(['ERROR_HPP_VALIDATION_EXCEEDED_NO_BYPASS']);
              break;
            case 'accout-code-not-found':
              // case 'account-code-not-found': // this typo is part of core --
              // FIXME: change core
              setErrorMessage(['ERROR_HPP_VALIDATION']);
              break;
            case 'invalid':
              setErrorMessage(['ERROR_HPP_VALIDATION_INTERNAL']);
              break;
            default:
              console.error('validateEpicAccountCode- unknown status: ' + result.status, result);
              setErrorMessage(['ERROR_HPP_VALIDATION_UNKNOWN']);
          }
        },
      },
    ),
    { retry: false },
  );

  const [selectedValidatedEpicClientId, setSelectedValidatedEpicClientId] = useState('unset');
  const epicClients = useMemo(
    () => [
      ...(customerUser?.validatedEpicClients.map((c) => ({
        ...c,
        displayName: `${c.epicLookupCode} - ${c.epicAccountName}`,
      })) ?? []),
      {
        displayName: t('SELECT_NEW_ACCOUNT'),
        id: 'new',
      },
    ],
    [customerUser?.validatedEpicClients, t],
  );
  // make sure selected client is valid, and if not, select best guess
  useEffect(() => {
    if (
      selectedValidatedEpicClientId === 'new' ||
      customerUser?.validatedEpicClients.find((vec) => vec.id === selectedValidatedEpicClientId)
    )
      return;

    const hppLastClient = customerUser?.validatedEpicClients.find(
      (vec) =>
        vec.merchantAccount.id === agencyDetail?.merchantAccountId &&
        vec.epicClientId === lastValidatedEpicClient?.clientId,
    );
    if (hppLastClient) setSelectedValidatedEpicClientId(hppLastClient.id);
    else {
      const hppClient = customerUser?.validatedEpicClients.find(
        (vec) => vec.merchantAccount.id === agencyDetail?.merchantAccountId,
      );
      if (hppClient) setSelectedValidatedEpicClientId(hppClient.id);
      else {
        const lastClient = customerUser?.validatedEpicClients.find(
          (vec) => vec.epicClientId === lastValidatedEpicClient?.clientId,
        );
        if (lastClient) setSelectedValidatedEpicClientId(lastClient.id);
        else if (selectedValidatedEpicClientId !== 'new') setSelectedValidatedEpicClientId('new');
      }
    }
  }, [
    agencyDetail?.merchantAccountId,
    customerUser?.validatedEpicClients,
    lastValidatedEpicClient?.clientId,
    selectedValidatedEpicClientId,
  ]);
  // sync client with selected validated client
  useEffect(() => {
    const selectedClient = customerUser?.validatedEpicClients.find((vec) => vec.id === selectedValidatedEpicClientId);
    if (selectedClient)
      setClient({
        accountName: selectedClient.epicAccountName,
        clientId: selectedClient.epicClientId,
        lookupCode: selectedClient.epicLookupCode,
        phoneNumber: selectedClient.phoneNumber,
        postalCode: selectedClient.postalCode,
      });
  }, [customerUser, selectedValidatedEpicClientId]);

  //
  // policies mode
  const policies = useQuery({
    queryKey: ['autopay/policies', client?.clientId],
    queryFn: wrapQuery(() =>
      ApiClient.getInstance(agencyDetail?.token).getCustomerUserPolicies(
        client!.clientId,
        new URLSearchParams({ expirationDate_after: format(warningCutoff, 'yyyy-MM-dd') }),
      ),
    ),
    enabled: !!client && autopayType === AutopayType.POLICIES,
    retry: false,
    refetchOnWindowFocus: false,
  });
  const policiesColumns = useMemo(
    () =>
      [
        {
          name: 'policyType',
          title: t('POLICY_TYPE'),
          cellRenderer: (row) => row.policyType.description,
          sortValue: (row) => row.policyType.description,
        },
        {
          name: 'policyNumber',
          title: t('POLICY_NUMBER'),
        },
        {
          name: 'expiration',
          title: t('EXPIRATION'),
          cellRenderer: (row) => reformatYMDtoMDY(row.expirationOn) || '-',
          sortValue: (row) => row.expirationOn || '-',
        },
      ] satisfies DataGridColumnType<EpicPolicy>[],
    [t],
  );
  const [selectedPolicyIds, setSelectedPolicyIds] = useState<string[]>([]);

  //
  // invoices mode
  const invoices = useQuery({
    queryKey: ['autopay/invoices', client?.clientId],
    queryFn: wrapQuery(() => ApiClient.getInstance(agencyDetail?.token).getCustomerUserInvoices(client!.clientId), {
      transformData: (invoices) =>
        // only show invoices that will expire 3 days in the future
        invoices.filter((i) => new Date(i.dueDate).getTime() > warningCutoff.getTime()),
    }),
    enabled: !!client && autopayType === AutopayType.INVOICES,
    retry: false,
    refetchOnWindowFocus: false,
  });
  const [selectedInvoices, setSelectedInvoices] = useState<InvoiceGroup[]>([]);

  //
  // payment method
  const [method, setMethod] = useState(PaymentMethod.Card);
  useEffect(() => {
    let newMethod = method;
    if (method === PaymentMethod.Card && !agencyDetail?.creditEnabled) newMethod = PaymentMethod.Ach;
    else if (method === PaymentMethod.Ach && !agencyDetail?.achEnabled) newMethod = PaymentMethod.Card;
    if (newMethod !== method) setMethod(newMethod);
  }, [agencyDetail, method]);
  const [selectedMethod, setSelectedMethod] = useState<StoredPaymentMethod>();
  const storedMethods = useQuery({
    queryKey: ['autopay/paymentMethods'],
    queryFn: wrapQuery(() => ApiClient.getInstance().getStoredPaymentMethods(), {
      transformData: (data) =>
        ({
          [PaymentMethod.Card]: [
            ...data.cards.map((c) => ({
              ...c,
              displayName:
                (c.paymentMethodNickname ? c.paymentMethodNickname + ' (' : '') +
                (cardCompanies[c.variant!] ?? t('CARD')) +
                (c.card?.number
                  ? ' ' + t('ENDING_WITH', undefined, { lastFourDigits: c.card.number.slice(-4) } as any)
                  : '') +
                (c.paymentMethodNickname ? ')' : ''),
            })),
            { recurringDetailReference: 'new', displayName: t('ENTER_A_NEW_PAYMENT_METHOD') },
          ],
          [PaymentMethod.Ach]: [
            ...data.ach.map((c) => ({
              ...c,
              displayName:
                (c.paymentMethodNickname ? c.paymentMethodNickname + ' (' : '') +
                (c.bank?.ownerName || '') +
                (c.bank?.ownerName && c.bank.bankAccountNumber ? ' - ' : '') +
                (c.bank?.bankAccountNumber ? `*****${c.bank.bankAccountNumber.slice(-4)}` : '') +
                (c.paymentMethodNickname ? ')' : ''),
            })),
            { recurringDetailReference: 'new', displayName: t('ENTER_A_NEW_PAYMENT_METHOD') },
          ],
        } as unknown as Record<PaymentMethod, (StoredPaymentMethod & { displayName: string })[]>),
    }),
    retry: false,
    refetchOnWindowFocus: false,
  });

  const [ccFeesAccepted, setCcFeesAccepted] = useState(false);
  useEffect(() => setCcFeesAccepted(method !== PaymentMethod.Card), [method]);

  //
  // enrollment button
  const enroll = useMutation(
    wrapMutation(async () => {
      return await ApiClient.getInstance().upsertAutopayEnrollment({
        enrollmentId: enrollment?.id,
        epicClient: {
          epicClientId: client!.clientId,
          epicAccountName: client!.accountName,
          epicLookupCode: client!.lookupCode,
          merchantAccountId: agencyDetail!.merchantAccountId,
          postalCode: client!.postalCode,
          phoneNumber: client!.phoneNumber ?? undefined,
        },
        storedPaymentMethod: {
          recurringDetailReference: selectedMethod!.recurringDetailReference,
        },
        type: autopayType,
        policies:
          autopayType === AutopayType.POLICIES
            ? policies
                .data!.filter((p) => selectedPolicyIds.includes(p.id))
                .map((p) => ({
                  epicPolicyId: p.id,
                  policyNumber: p.policyNumber,
                  dueDate: p.expirationOn,
                  lineOfBusiness: p.policyType.code,
                }))
            : undefined,
        invoices:
          autopayType === AutopayType.INVOICES
            ? selectedInvoices.map((i) => ({
                invoiceNumber: i.invoiceNumber,
                dueDate: i.dueDate,
                description: 'todo',
              }))
            : undefined,
      });
    }),
    {
      onSuccess: async (_response) => {
        await refreshCustomerUser();
        if (enrollment) goBackToList();
        else {
          // (this component will show the success confirmation screen)
        }
      },
      retry: false,
    },
  );

  //
  // cancel button
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  if (agencyDetailIsLoading || !agencyDetail) return <Loading />;

  if (enroll.isSuccess)
    return (
      <SectionV2
        title={t('YOU_ARE_ENROLLED_IN_AUTO_PAY')}
        description={t('AUTOPAY_ENROLLED_MESSAGE', undefined, {
          digits:
            selectedMethod!.bank?.bankAccountNumber?.slice(-4) ?? selectedMethod!.card?.number?.slice(-4) ?? '****',
          epicLookupCode: client!.lookupCode,
        } as any)}
      >
        <FlexRow gap="1.5rem">
          <Button onClick={() => setCancelModalOpen(true)}>{t('CANCEL_AUTO_PAY')}</Button>
          <Button onClick={goBackToList} type="primary">
            {t('MANAGE_AUTO_PAY')}
          </Button>
        </FlexRow>

        <CancelAutopayModal
          modalOpen={cancelModalOpen}
          onClose={() => setCancelModalOpen(false)}
          enrollmentIdToCancel={enroll.data.enrollmentId}
        />
      </SectionV2>
    );

  return (
    <FlexCol gap="1.5rem">
      <SectionV2 title={t('AUTOPAY_DETAILS')} description={t('AUTOPAY_SETUP_INSTRUCTIONS')} type={SectionTypes.H2}>
        {!!customerUser?.validatedEpicClients?.length && (
          <ComboboxObject
            name="validatedEpicClient"
            items={epicClients}
            label={t('SELECT_EPIC_ACCOUNT')}
            displayField="displayName"
            className={classes.methodDropdown}
            value={selectedValidatedEpicClientId}
            onChange={(e: ADSChangeEvent) => {
              setClient(undefined);
              setSelectedValidatedEpicClientId(e.target.value.id);
            }}
          />
        )}

        {/* ACCOUNT VALIDATION FORM */}
        {selectedValidatedEpicClientId === 'new' && (
          <Form schema={accountSchema} onSubmit={(values) => validateAccount.mutate(values)}>
            <FlexCol gap="1.5rem" alignItems="start" className="mt-150">
              <FlexRow gap="2rem" flexWrap="wrap">
                <TextField name="accountNumber" label={t('ACCOUNT_NUMBER')} />
                <PhoneField name="phoneNumber" label={t('PHONE_NUMBER')} hideExt />
                <TextField name="postalCode" label={t('POSTAL_CODE')} />
              </FlexRow>
              <Button submit isLoading={validateAccount.isLoading} type={'primary'}>
                {t('NEXT')}
              </Button>
              {errorMessage && <ErrorAlert errorMessage={errorMessage} />}
            </FlexCol>
          </Form>
        )}

        {/* TYPE RADIO BUTTONS */}
        {!!client && (
          <FlexCol gap="1.5rem" className="mt-150">
            <RadioGroup
              label={t('SET_UP_AUTO_PAY_FOR')}
              value={autopayType}
              onChange={(e: ADSChangeEvent) => setAutopayType(e.target.value)}
            >
              {agencyDetail.autopayEnableAccount && (
                <Radio value={AutopayType.ACCOUNT}>
                  <FlexRow alignItems="center" gap="2px">
                    {t('ACCOUNT')} <InfoTooltip helpText={t('AUTOPAY_ACCOUNT_DESC')} />
                  </FlexRow>
                </Radio>
              )}
              {agencyDetail.autopayEnablePolicy && (
                <Radio value={AutopayType.POLICIES}>
                  <FlexRow alignItems="center" gap="2px">
                    {t('POLICIES')} <InfoTooltip helpText={t('AUTOPAY_POLICY_DESC')} />
                  </FlexRow>
                </Radio>
              )}
              {agencyDetail.autopayEnableInvoice && (
                <Radio value={AutopayType.INVOICES}>
                  <FlexRow alignItems="center" gap="2px">
                    {t('INVOICES')} <InfoTooltip helpText={t('AUTOPAY_INVOICE_DESC')} />
                  </FlexRow>
                </Radio>
              )}
            </RadioGroup>
          </FlexCol>
        )}
      </SectionV2>

      {/* POLICIES */}
      {!!client && autopayType === AutopayType.POLICIES && (
        <SectionV2 title={t('SELECT_POLICIES')} description={t('AUTOPAY_SELECT_POLICIES')} type={SectionTypes.H3}>
          <FlexCol gap="1.5rem" alignItems="start">
            <DataGrid<EpicPolicy>
              maxHeight={500}
              rows={policies.data ?? [undefined]}
              columns={policiesColumns}
              searchable={{ enabled: true }}
              checkbox={{
                enabled: true,
                hideCheckAll: (policies.data?.length ?? 0) > 50,
              }}
              onCheckedRowsChange={(e) => {
                setSelectedPolicyIds(e.items.filter((i) => i.checked).map((i) => i.id as any));
              }}
            />
          </FlexCol>
        </SectionV2>
      )}

      {/* INVOICES */}
      {!!client && autopayType === AutopayType.INVOICES && (
        <SectionV2 title={t('SELECT_INVOICES')} description={t('AUTOPAY_SELECT_INVOICES')} type={SectionTypes.H3}>
          <InvoiceDataGrid invoices={invoices.data ?? null} locale={locale} onChange={setSelectedInvoices} />
        </SectionV2>
      )}

      {/* STORED PAYMENT METHODS */}
      {!!client && (
        <SectionV2 title={t('PAYMENT_METHOD')} description={t('AUTOPAY_METHOD_DESC')} type={SectionTypes.H3}>
          <FlexCol gap="1.5rem" alignItems="start">
            {agencyDetail.achEnabled && agencyDetail.creditEnabled && (
              <RadioGroup value={method} onChange={(e: ADSChangeEvent) => setMethod(e.target.value)}>
                <Radio value="card">
                  {t('CREDIT_CARD')} ({(agencyDetail.creditFeePercent / 100).toFixed(1)}% {t('FEE')})
                </Radio>
                <Radio value="ach">
                  {t('ACH')} (
                  {toIntlFormat(
                    {
                      amount: agencyDetail.achFeeAmount,
                      currencyCode,
                    },
                    locale,
                  )}{' '}
                  {t('FEE')})
                </Radio>
              </RadioGroup>
            )}

            {!!storedMethods.data?.[method].length && (
              <ComboboxObject
                className={classes.methodDropdown}
                items={storedMethods.data[method]}
                label={t(method === PaymentMethod.Card ? 'CREDIT_CARD' : 'ACH')}
                name="method"
                itemsIdField="recurringDetailReference"
                displayField="displayName"
                placeholder={t(method === PaymentMethod.Card ? 'SELECT_A_CARD' : 'SELECT_AN_ACCOUNT')}
                value={selectedMethod?.recurringDetailReference}
                onChange={(e: ADSChangeEvent) => setSelectedMethod(e.target.value)}
              />
            )}

            <AddPaymentMethodModal
              onClose={() => setSelectedMethod(undefined)}
              onSuccess={async (payment) => {
                const refetchResult = await storedMethods.refetch();
                const updatedMethods = Object.values(refetchResult.data ?? {}).flat();
                const newMethod =
                  updatedMethods.find((m) => m.firstPspReference === payment.pspReferenceId) ?? updatedMethods.at(-1);
                setSelectedMethod(newMethod);
                if (newMethod?.firstPspReference !== payment.pspReferenceId)
                  datadogLogs.logger.warn(
                    `Saved new method with PSP reference ${payment.pspReferenceId} but didn't see it in the list of methods returned`,
                    { updatedMethods },
                  );
              }}
              paymentMethod={
                selectedMethod?.recurringDetailReference === 'new'
                  ? method === PaymentMethod.Card
                    ? 'scheme'
                    : 'ach'
                  : null
              }
              paymentSessionToken={agencyDetail.token}
            />

            {method === PaymentMethod.Card && (
              <RowCol className={paymentMethodContainerClasses.checkboxWrapper}>
                <AcceptCCFeeCheckbox isChecked={ccFeesAccepted} onCheckboxChange={setCcFeesAccepted} />
              </RowCol>
            )}
          </FlexCol>
        </SectionV2>
      )}

      {/* FOOTER */}
      {!!client && (
        <>
          <FlexRow gap="1.5rem" justifyContent="end">
            <Button onClick={() => goBackToList()}>{t('CANCEL')}</Button>
            <Button
              type="primary"
              isLoading={enroll.isLoading}
              onClick={() => enroll.mutate()}
              disabled={
                enroll.isLoading ||
                !client ||
                !selectedMethod ||
                selectedMethod.recurringDetailReference === 'new' ||
                !ccFeesAccepted ||
                (autopayType === AutopayType.POLICIES && !selectedPolicyIds.length) ||
                (autopayType === AutopayType.INVOICES && !selectedInvoices.length)
              }
            >
              {t(enrollment ? 'SAVE' : 'ENROLL_IN_AUTOPAY')}
            </Button>
          </FlexRow>

          <span className={classes.legal}>
            {t('AUTOPAY_LEGAL_CARD', undefined, {
              lastFourDigits: selectedMethod?.card?.number?.slice(-4) ?? '****',
            } as any)}
          </span>
        </>
      )}
    </FlexCol>
  );
};
